import { gql } from "@apollo/client";
import CommentFieldsFragment from "~/src/components/comments/graphql/fragments/CommentFieldsFragment";

export default gql`
  mutation WriteComment($targetId: ObjectId!, $targetType: CommentTargetType!, $body: JSON!) {
    createComment(targetId: $targetId, targetType: $targetType, body: $body) {
      ...CommentFields
    }
  }

  ${CommentFieldsFragment}
`;
