/* eslint-disable react/default-props-match-prop-types */
import { Component, ReactElement } from "react";

interface IDelayedHoverChildProps {
  hover: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

interface IDelayedHoverProps {
  ms: number;
  children: (state: IDelayedHoverChildProps) => ReactElement | null;
}

interface IDelayedHoverState {
  hover: boolean;
}

export default class DelayedHover extends Component<IDelayedHoverProps, IDelayedHoverState> {
  constructor(props: IDelayedHoverProps) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  timeout!: NodeJS.Timeout;

  handleMouseEnter = () => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        hover: true,
      });
    }, this.props.ms);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        hover: false,
      });
    }, this.props.ms / 2);
  };

  render() {
    return this.props.children({
      hover: this.state.hover,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    });
  }
}
