import React from "react";

export default function MakersProIcon(props: React.SVGProps<any>) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
      <defs>
        <linearGradient x1="12.566%" y1="11.838%" x2="90.081%" y2="100%" id="fsa2h4shrarhj">
          <stop stopColor="#55A2FA" offset="0%" />
          <stop stopColor="#B4EC51" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M40 0c22.063 0 40 17.937 40 40S62.063 80 40 80 0 62.063 0 40 17.937 0 40 0z"
          fill="url(#fsa2h4shrarhj)"
        />
        <g fill="#FFF">
          <path d="M28.626 64v-3.094h1.708c1.946 0 3.262-1.456 3.262-3.472s-1.316-3.444-3.262-3.444h-4.158V64h2.45zm1.204-5.194h-1.204V56.09h1.246c.812 0 1.232.546 1.232 1.344s-.42 1.372-1.274 1.372zM38.082 64v-3.374h1.148L41.008 64h2.73l-1.946-3.5c-.238-.42-.378-.602-.378-.602v-.028c.994-.504 1.498-1.666 1.498-2.73 0-1.456-.714-2.478-1.848-2.912-.434-.168-.924-.238-1.946-.238h-3.486V64h2.45zm1.064-5.474h-1.064V56.09h.826c1.232 0 1.512.448 1.512 1.204 0 .798-.49 1.232-1.274 1.232zm11.136 5.642c3.024 0 5.236-2.296 5.236-5.236 0-2.87-2.212-5.11-5.236-5.11-3.024 0-5.236 2.24-5.236 5.11 0 2.94 2.212 5.236 5.236 5.236zm0-2.24c-1.498 0-2.702-1.288-2.702-2.996 0-1.638 1.204-2.87 2.702-2.87s2.702 1.232 2.702 2.87c0 1.708-1.204 2.996-2.702 2.996zM49.706 28.177l-7.992 16.47h-3.899l-7.992-16.47V47H23V17h8.967l7.603 16.47L47.367 17h8.967v30h-6.628z" />
        </g>
      </g>
    </svg>
  );
}
