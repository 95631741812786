import { ApolloError } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import React from "react";
import CancelVerificationRequestFromProjectByGovernmentId from "~/lib/mutations/CancelVerificationRequestFromProjectByGovernmentId";
import {
  ICancelVerificationRequestFromProjectByGovernmentIdMutation,
  ICancelVerificationRequestFromProjectByGovernmentIdMutationVariables,
} from "~/lib/mutations/__generated__/CancelVerificationRequestFromProjectByGovernmentId.generated";

interface ICancelVerificationRequestChildrenState {
  error?: ApolloError;
  loading: boolean;
  cancelVerificationRequest: (governmentId: string) => void;
}

interface ICancelVerificationRequestProps {
  projectId: string;
  children: (state: ICancelVerificationRequestChildrenState) => any;
}

export default function CancelVerificationRequest({ projectId, children }: ICancelVerificationRequestProps) {
  return (
    <Mutation<
      ICancelVerificationRequestFromProjectByGovernmentIdMutation,
      ICancelVerificationRequestFromProjectByGovernmentIdMutationVariables
    >
      mutation={CancelVerificationRequestFromProjectByGovernmentId}
    >
      {(cancelVerificationRequest, { loading, error }) => {
        return children({
          error,
          loading,
          cancelVerificationRequest: (governmentId: string) => {
            return cancelVerificationRequest({
              variables: {
                projectId,
                governmentId,
              },
            });
          },
        });
      }}
    </Mutation>
  );
}
