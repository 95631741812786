import React from "react";
import { useIntl } from "react-intl";
import SeoHead from "~/components/seo/SeoHead";

interface IProjectPageSeoProps {
  projectTitle: string;
  projectDescription: string | null;
}

export default function ProjectPageSeo({ projectTitle, projectDescription }: IProjectPageSeoProps) {
  const intl = useIntl();

  return (
    <SeoHead
      title={`${projectTitle} - Govlaunch ${intl.formatMessage({ defaultMessage: 'Projects', id: 'UxTJRa' })}`}
      description={projectDescription || projectTitle}
    />
  );
}
