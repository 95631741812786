import { gql } from "@apollo/client";
import { ProjectFieldsFragment } from "@govlaunch/project";

export default gql`
  mutation VerifyProject($projectId: ObjectId!, $governmentId: ObjectId!) {
    verifyProject(projectId: $projectId, governmentId: $governmentId) {
      _id
      project {
        ...ProjectFields
      }
    }
  }

  ${ProjectFieldsFragment}
`;
