import set from "lodash/fp/set";
import { SchemaOf, ValidationError } from "yup";

export default function validate(schema: SchemaOf<any>): (values: any) => Record<string, string> {
  return values => {
    try {
      schema.validateSync(values, {
        abortEarly: false,
      });

      return {};
    } catch (e: any) {
      return e.inner.reduce((errors: Record<string, string>, error: ValidationError) => {
        if (typeof error.path !== "undefined") {
          return set(error.path, error.message, errors);
        }
      }, {});
    }
  };
}
