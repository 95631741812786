import { useMutation } from "@apollo/client";
import {
  MoreProjects,
  ProjectAttachments,
  ProjectAwards,
  ProjectEntity,
  ProjectGovernments,
  ProjectGroups,
  ProjectHeader,
  ProjectIntroduction,
  ProjectLinks,
  ProjectLoading,
  ProjectNewsworthy,
  ProjectOutcome,
  ProjectProducts,
  ProjectTags,
  ProjectVendors,
  ProjectViewerVerification,
  RelatedProjects,
} from "@govlaunch/project";
import { Box, SimpleGrid } from "@govlaunch/web";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelfie } from "~/components/auth/Auth";
import ProjectPageSeo from "~/components/seo/ProjectPageSeo";
import UpgradeToMakersProBanner from "~/components/vendor/profile/UpgradeToMakersProBanner";
import { refetchQueries } from "~/lib/apollo/RefetchQueriesFixLink";
import ToggleBookmarkMutation from "~/lib/mutations/ToggleBookmarkMutation";
import ToggleUpvoteMutation from "~/lib/mutations/ToggleUpvoteMutation";
import {
  IToggleBookmarkMutation,
  IToggleBookmarkMutationVariables,
} from "~/lib/mutations/__generated__/ToggleBookmarkMutation.generated";
import {
  IToggleUpvoteMutation,
  IToggleUpvoteMutationVariables,
} from "~/lib/mutations/__generated__/ToggleUpvoteMutation.generated";
import { ISelfieQuery } from "~/lib/queries/__generated__/SelfieQuery.generated";
import { IBookmarkType, ICommentTargetType, IUpvoteTargetType } from "~/types/types";
import nextLinkHref from "~/src/utils/nextLinkHref";
import CommentsList from "~/src/components/comments/CommentsList";
import GatedCommentsList from "~/src/components/comments/GatedCommentsList";
import createProjectLinkWrap from "~/src/components/project/createProjectLinkWrap";
import ArchiveProjectMutation from "~/src/components/project/graphql/ArchiveProjectMutation";
import DeclineProjectVerificationMutation from "~/src/components/project/graphql/DeclineProjectVerificationMutation";
import VerifyProjectMutation from "~/src/components/project/graphql/VerifyProjectMutation";
import {
  IArchiveProjectMutation,
  IArchiveProjectMutationVariables,
} from "~/src/components/project/graphql/__generated__/ArchiveProjectMutation.generated";
import {
  IDeclineProjectVerificationMutation,
  IDeclineProjectVerificationMutationVariables,
} from "~/src/components/project/graphql/__generated__/DeclineProjectVerificationMutation.generated";
import { IProjectBySlugQuery } from "~/src/components/project/graphql/__generated__/ProjectBySlugQuery.generated";
import {
  IVerifyProjectMutation,
  IVerifyProjectMutationVariables,
} from "~/src/components/project/graphql/__generated__/VerifyProjectMutation.generated";
import ProjectCollaborators from "~/src/components/project/ProjectCollaborators";
import ProjectPendingVerifications from "~/src/components/project/ProjectPendingVerifications";

type TProject = IProjectBySlugQuery["projectBySlug"];

interface IProjectWithActionsProps {
  loading: boolean;
  project: TProject;
  onClickProject?: (project: Pick<NonNullable<TProject>, "_id" | "slug">) => void;
  onDeleteProject: () => void;
  onVerify?: () => void;
  translationButton?: () => ReactElement | null;
  isInModal?: boolean | null;
}

export default function ProjectWithActions({
  loading,
  project,
  onDeleteProject,
  onClickProject,
  onVerify,
  translationButton,
}: IProjectWithActionsProps) {
  const viewer = useSelfie();
  const router = useRouter();

  const [toggleUpvote] = useMutation<IToggleUpvoteMutation, IToggleUpvoteMutationVariables>(ToggleUpvoteMutation);
  const [toggleBookmark] = useMutation<IToggleBookmarkMutation, IToggleBookmarkMutationVariables>(
    ToggleBookmarkMutation,
  );

  const [archiveProject] = useMutation<IArchiveProjectMutation, IArchiveProjectMutationVariables>(
    ArchiveProjectMutation,
    {
      refetchQueries: refetchQueries(["Vendor", "CompanyAccountBillingInfo"]),
    },
  );

  const [verifyProject] = useMutation<IVerifyProjectMutation, IVerifyProjectMutationVariables>(VerifyProjectMutation, {
    refetchQueries: refetchQueries(["ProjectsByGovernment", "VerifiedGovernmentProjects"]),
  });

  const [declineProjectVerification] = useMutation<
    IDeclineProjectVerificationMutation,
    IDeclineProjectVerificationMutationVariables
  >(DeclineProjectVerificationMutation, {
    refetchQueries: refetchQueries(["ProjectsByGovernment", "VerifiedGovernmentProjects"]),
  });

  const renderLinkWrap = createProjectLinkWrap(router);

  const renderEditLinkWrap = (children: ReactElement) => {
    return (
      <Link href="/projects/[projectSlug]/edit" as={`/projects/${project?.slug}/edit`} passHref={true}>
        {children}
      </Link>
    );
  };

  if (!loading && viewer && viewer.__typename === "VendorUser" && !project?.viewerCanView) {
    return <UpgradeToMakersProBanner />;
  }

  return (
    <>
      {loading && <ProjectLoading />}

      {!loading && project && (
        <>
          <ProjectPageSeo projectTitle={project.title} projectDescription={project.description} />
          <Box px={[1, 1, 0]}>
            <SimpleGrid gridRowGap={4} backgroundColor="white">
              <ProjectHeader
                project={project}
                renderEditLinkWrap={renderEditLinkWrap}
                onDelete={async () => {
                  await archiveProject({
                    variables: {
                      projectId: project._id,
                    },
                  });

                  onDeleteProject();
                }}
                actionButtons={{
                  upvote: !!viewer,
                  bookmark: !!viewer,
                }}
                onToggleUpvote={() => {
                  toggleUpvote({
                    variables: {
                      targetType: IUpvoteTargetType.Project,
                      targetId: project._id,
                    },
                  });
                }}
                onToggleBookmark={() => {
                  toggleBookmark({
                    variables: {
                      targetType: IBookmarkType.Project,
                      targetId: project._id,
                    },
                  });
                }}
                translationButton={translationButton}
              />

              {/* <ProjectIntroduction project={project} isInModal={isInModal} /> */}
              <ProjectIntroduction project={project} />

              <ProjectTags
                tags={project.tags}
                renderTagLinkWrap={(tag, children) => {
                  const linkHref = nextLinkHref("/collections/[collectionSlug]/[[...tab]]", {
                    collectionSlug: tag.slug,
                  });

                  const linkAs = `/collections/${tag.slug}/projects`;

                  return (
                    <Link key={linkAs} href={linkHref} as={linkAs}>
                      <a href={linkAs}>{children}</a>
                    </Link>
                  );
                }}
              />

              <ProjectPendingVerifications project={project} />

              <ProjectViewerVerification
                locationsPendingVerification={project.locationsPendingVerification}
                onVerifyPendingLocation={async (locationId) => {
                  await verifyProject({
                    variables: {
                      projectId: project._id,
                      governmentId: locationId,
                    },
                  });

                  if (onVerify) {
                    onVerify();
                  }
                }}
                onDeclinePendingLocation={async (locationId) => {
                  await declineProjectVerification({
                    variables: {
                      projectId: project._id,
                      governmentId: locationId,
                    },
                  });

                  if (onVerify) {
                    onVerify();
                  }
                }}
              />

              <ProjectEntity project={project} renderLinkWrap={renderLinkWrap} />

              <ProjectGovernments governments={project.locations} renderLinkWrap={renderLinkWrap} />

              <ProjectProducts products={project.products} renderLinkWrap={renderLinkWrap} />

              <ProjectVendors vendors={project.vendors} renderLinkWrap={renderLinkWrap} />

              <ProjectGroups groups={project.groups} renderLinkWrap={renderLinkWrap} />

              <ProjectLinks links={project.links} />

              <ProjectAttachments attachments={project.attachments} />

              <ProjectNewsworthy newsworthy={project.newsworthy} />

              <ProjectOutcome outcome={project.outcome} outcomeStatement={project.outcomeStatement} />

              <ProjectAwards awards={project.awards} />

              <ProjectCollaborators project={project} />

              <MoreProjects project={project} onClickProject={onClickProject} renderLinkWrap={renderLinkWrap} />

              <RelatedProjects
                relatedProjects={project.relatedProjects}
                onClickProject={onClickProject}
                renderLinkWrap={renderLinkWrap}
              />

              <Box my={1}>
                {project.viewerCanComment ? (
                  <CommentsList
                    targetId={project._id}
                    targetType={ICommentTargetType.Project}
                    viewerCanComment={project.viewerCanComment}
                  />
                ) : (
                  <GatedCommentsList text={getGatedCommentListMessage(project, viewer)} />
                )}
              </Box>
            </SimpleGrid>
          </Box>
        </>
      )}
    </>
  );
}

function getGatedCommentListMessage(project: NonNullable<TProject>, viewer: ISelfieQuery["selfie"]) {
  if (!viewer) {
    return null;
  }

  if (project.__typename === "GroupProject") {
    if (viewer.__typename === "PendingUser") {
      if (viewer.role === "CITIZEN") {
        return (
          <span>
            <span css={{ marginRight: 2 }}>🚫</span>{" "}
            <FormattedMessage defaultMessage="You must be a Government or Community verified user to join the discussion." id="Bhriss" />
          </span>
        );
      }

      if (!viewer.verified) {
        return (
          <span>
            <span css={{ marginRight: 2 }}>🚫</span>{" "}
            <FormattedMessage defaultMessage="You must be a verified user to join the discussion." id="tRqSX+" />
          </span>
        );
      }
    }

    if (viewer.__typename === "VendorUser") {
      return (
        <span>
          <span css={{ marginRight: 2 }}>🚫</span>{" "}
          <FormattedMessage defaultMessage="You must be a Government user to join the discussion." id="eFhXpV" />
        </span>
      );
    }

    return (
      <span>
        <span css={{ marginRight: 2 }}>🚫</span>{" "}
        <FormattedMessage defaultMessage="You must be a group member to join the discussion." id="UYXzML" />
      </span>
    );
  }

  if (project.__typename === "GovernmentProject") {
    if (viewer.__typename === "PendingUser") {
      if (viewer.role === "CITIZEN") {
        return (
          <span>
            <span css={{ marginRight: 2 }}>🚫</span>{" "}
            <FormattedMessage defaultMessage="You must be a Government or Community verified user to join the discussion." id="Bhriss" />
          </span>
        );
      }

      if (!viewer.verified) {
        return (
          <span>
            <span css={{ marginRight: 2 }}>🚫</span>{" "}
            <FormattedMessage defaultMessage="You must be a verified user to join the discussion." id="tRqSX+" />
          </span>
        );
      }
    }

    if (viewer.__typename === "VendorUser") {
      return (
        <span>
          <span css={{ marginRight: 2 }}>🚫</span>{" "}
          <FormattedMessage defaultMessage="You must be a Government user to join the discussion." id="eFhXpV" />
        </span>
      );
    }
  }

  if (project.__typename === "VendorProject") {
    if (viewer.__typename === "PendingUser") {
      if (viewer.role === "CITIZEN") {
        return (
          <span>
            <span css={{ marginRight: 2 }}>🚫</span>{" "}
            <FormattedMessage defaultMessage="You must be a Government or Community verified user to join the discussion." id="Bhriss" />
          </span>
        );
      }

      if (!viewer.verified) {
        return (
          <span>
            <span css={{ marginRight: 2 }}>🚫</span>{" "}
            <FormattedMessage defaultMessage="You must be a verified user to join the discussion." id="tRqSX+" />
          </span>
        );
      }
    }

    if (viewer.__typename === "VendorUser") {
      return (
        <span>
          <span css={{ marginRight: 2 }}>🚫</span>{" "}
          <FormattedMessage defaultMessage="You must be a Government user to join the discussion." id="eFhXpV" />
        </span>
      );
    }
  }
}
