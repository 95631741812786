import { Box } from "@govlaunch/web";
import React from "react";
import { FormattedMessage } from "react-intl";
import useIsMobile from "~/lib/hooks/useIsMobile";

interface ITranslationButtonProps {
  showOriginal?: boolean | null;
  onClick: React.MouseEventHandler;
  paddingY?: string | number;
}

export default function TranslationButton({ showOriginal, onClick, paddingY }: ITranslationButtonProps) {
  const isMobile = useIsMobile();

  return (
    <Box
      color={isMobile ? "gray.900" : "blue.600"}
      fontSize={isMobile ? "12px" : "14px"}
      border={isMobile ? "1px solid #ADB6C3" : ""}
      borderRadius="4px"
      py={paddingY || "8px"}
      px="10px"
      onClick={onClick}
      _hover={{
        cursor: "pointer",
        opacity: 0.5,
      }}
      role="button"
    >
      {showOriginal ? (
        <FormattedMessage defaultMessage="see original" id="/UVQql" />
      ) : (
        <FormattedMessage defaultMessage="see translation" id="jJqiqa" />
      )}
    </Box>
  );
}
