import { Mutation } from "@apollo/client/react/components";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import Link from "next/link";
import React from "react";
import { IntlShape, useIntl } from "react-intl";
import Tooltip from "~/components/Tooltip";
import RemoveInvitee from "~/lib/mutations/RemoveInvitee";
import {
  IRemoveInviteeMutation,
  IRemoveInviteeMutationVariables,
} from "~/lib/mutations/__generated__/RemoveInvitee.generated";
import { ICompany, IGovernment, IGovernmentUser, IUser, IVendorUser } from "~/types/types";
import { useSelfie } from "~/components/auth/Auth";
import QuestionMark from "~/components/icons/QuestionMarkIcon";

interface ICollaborator {
  user: IUser;
  viewerCanRemove: boolean;
  targetId: string;
  targetType: "PROJECT" | "STORY" | "GOVERNMENT" | "VENDOR";
  removeText?: string;
  size?: number;
}

const Collaborator: React.FunctionComponent<ICollaborator> = ({
  user,
  viewerCanRemove,
  targetId,
  targetType,
  removeText = "Remove Collaborator",
  size = 54,
}) => {
  const intl = useIntl();
  const loggedInUser = useSelfie();

  return (
    <Tooltip
      backgroundColor={palette.innovatorBlue}
      borderColor={palette.innovatorBlue}
      render={() => (
        <div
          css={{
            padding: 6,
            color: palette.white,
          }}
        >
          <p
            css={{
              margin: 0,
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {user.fullName ? user.fullName : getCollaboratorLabel(user, intl)}
          </p>
          <p
            css={{
              margin: 0,
              fontSize: 12,
            }}
          >
            {getUserFrom(user, intl)}
          </p>
        </div>
      )}
    >
      {({ bind, innerRef }) => (
        <Tooltip
          backgroundColor={palette.red}
          borderColor={palette.red}
          render={() => (
            <div
              css={{
                padding: 6,
                fontWeight: 600,
                color: palette.white,
                fontSize: 14,
              }}
            >
              {removeText}
            </div>
          )}
        >
          {({ bind: removeBind, innerRef: removeInnerRef }) => (
            <div
              css={{
                display: "inline-flex",
                position: "relative",
              }}
            >
              <div
                css={{
                  position: "relative",
                }}
              >
                {(viewerCanRemove || (loggedInUser && loggedInUser._id === user._id)) && (
                  <Mutation<IRemoveInviteeMutation, IRemoveInviteeMutationVariables>
                    mutation={RemoveInvitee}
                    variables={{
                      targetId,
                      targetType,
                      inviteeId: user._id,
                    }}
                  >
                    {(remove) => (
                      <div
                        {...removeBind}
                        ref={removeInnerRef}
                        role="button"
                        css={{
                          cursor: "pointer",
                          position: "absolute",
                          top: -5,
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "100%",
                          right: -5,
                          width: 22,
                          height: 22,
                          background: palette.red,
                        }}
                        onClick={() => {
                          if (
                            window.confirm(
                              targetType === "GOVERNMENT"
                                ? intl.formatMessage({
                                    defaultMessage: 'Do you want to remove this team member?', id: 'zvf/vl',
                                  })
                                : intl.formatMessage({
                                    defaultMessage: 'Do you want to remove this collaborator?', id: 'l18Y6G',
                                  }),
                            )
                          ) {
                            remove().then(() => {
                              if (loggedInUser && loggedInUser._id === user._id) {
                                window.location.reload();
                              }
                            });
                          }
                        }}
                      >
                        <svg width={10} height={10}>
                          <path
                            fill="#FFF"
                            d="M6.188 5l3.566-3.565A.84.84 0 1 0 8.565.246L5 3.812 1.435.246A.84.84 0 1 0 .246 1.435L3.812 5 .246 8.565a.84.84 0 0 0 1.189 1.189L5 6.188l3.565 3.566a.84.84 0 1 0 1.189-1.189L6.188 5z"
                          />
                        </svg>
                      </div>
                    )}
                  </Mutation>
                )}

                {user.fullName ? (
                  <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${user.slug}`}>
                    <a>
                      <ProfilePicture
                        image={user.avatar}
                        name={user.fullName}
                        size={size}
                        {...bind}
                        innerRef={innerRef}
                        css={{
                          position: "static",
                        }}
                      />
                    </a>
                  </Link>
                ) : (
                  <div ref={innerRef} {...bind}>
                    <QuestionMark width={54} height={54} />
                  </div>
                )}
              </div>
            </div>
          )}
        </Tooltip>
      )}
    </Tooltip>
  );
};

function getCollaboratorLabel(user: IUser, intl: IntlShape) {
  if ((user as IGovernmentUser).government) {
    return intl.formatMessage(
      {
        defaultMessage: 'Member of {governmentName}', id: 'MQIpSq',
      },
      {
        governmentName: ((user as IGovernmentUser).government as IGovernment).name,
      },
    );
    // return `Member of ${((user as IGovernmentUser).government as IGovernment).name}`;
  }

  if ((user as IVendorUser).company) {
    return intl.formatMessage(
      {
        defaultMessage: 'Member of {vendorName}', id: '8XFmhR',
      },
      {
        vendorName: ((user as IVendorUser).company as ICompany).name,
      },
    );
    // return `Member of ${((user as IVendorUser).company as ICompany).name}`;
  }

  return intl.formatMessage({
    defaultMessage: 'Member of Govlaunch', id: 'hT39cQ',
  });
}

function getUserFrom(user: IUser, intl: IntlShape): string | null {
  // The government/vendor information will be shown on his label
  if (!user.fullName) {
    return null;
  }

  if (user.__typename === "GovernmentUser" && user.government) {
    // return `from ${user.government.name}`;
    return intl.formatMessage(
      {
        defaultMessage: 'from {governmentName}', id: 'g3HBt2',
      },
      { governmentName: user.government.name },
    );
  }

  if (user.__typename === "VendorUser" && user.company) {
    return intl.formatMessage(
      {
        defaultMessage: 'from {vendorName}', id: 'GsNpiZ',
      },
      { vendorName: user.company.name },
    );
    // return `from ${user.company.name}`;
  }

  return null;
}

export default Collaborator;
