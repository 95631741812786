import { useContext } from "react";
import SignInModalStateContext from "~/src/components/auth/SignInModalStateContext";

export default function useSignInModal() {
  const context = useContext(SignInModalStateContext);

  return {
    open: context.open,
    close: context.close,
    toggle: context.toggleOpen,
    isOpen: context.isOpen,
  };
}
