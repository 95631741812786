import { gql } from "@apollo/client";
import CommentFieldsFragment from "~/src/components/comments/graphql/fragments/CommentFieldsFragment";

export default gql`
  mutation EditComment($commentId: ObjectId!, $body: JSON!) {
    editComment(id: $commentId, body: $body) {
      ...CommentFields
    }
  }

  ${CommentFieldsFragment}
`;
