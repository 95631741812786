import { gql } from "@apollo/client";
import CommentFieldsFragment from "~/src/components/comments/graphql/fragments/CommentFieldsFragment";

export default gql`
  mutation ReplyComment($replyTo: ObjectId!, $body: JSON!) {
    replyComment(replyTo: $replyTo, body: $body) {
      _id
      ...CommentFields
    }
  }

  ${CommentFieldsFragment}
`;
