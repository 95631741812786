import React, { ReactNode } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@govlaunch/web";
import { FormattedMessage } from "react-intl";

interface IGroupLinkGatedContentProps {
  isOpen: boolean;
  message: ReactNode | string;
  onClose: () => any;
}

export default function GroupLinkGatedContent({ isOpen, message, onClose }: IGroupLinkGatedContentProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={2} />
        <ModalBody textAlign="center">{message}</ModalBody>

        <ModalFooter justifyContent="center">
          <Button variantColor="blue" mr={2} onClick={onClose}>
            <FormattedMessage defaultMessage="Close" id="rbrahO" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
