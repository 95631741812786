import React from "react";
import { useToggle } from "react-use";
import TranslationButton from "~/src/components/translation/TranslationButton";

interface ITranslateToProps {
  langExists: boolean;
  showTranslateButton: boolean;
  //translated: boolean;
  //setTranslated: (nextValue?: any) => void;
  refetch: () => any;
  refetchOriginal: () => any;
}

export default function TranslateTo({
  langExists,
  showTranslateButton,
  //translated,
  //setTranslated,
  refetch,
  refetchOriginal,
}: ITranslateToProps) {
  const [translated, setTranslated] = useToggle(langExists);

  if (!showTranslateButton && !translated) {
    return null;
  }

  if (translated) {
    return (
      <TranslationButton
        showOriginal={true}
        onClick={() => {
          setTranslated(false);

          refetchOriginal();
        }}
      />
    );
  }

  return (
    <TranslationButton
      onClick={() => {
        setTranslated(true);

        refetch();
      }}
    />
  );
}
