import React from "react";
import { IProjectFieldsFragment } from "@govlaunch/project";
import { Text, Heading, Tooltip, Avatar, Button, TCountryCode, Box, CountryFlag } from "@govlaunch/web";
import CancelVerificationRequest from "~/components/project/CancelVerificationRequest";
import Link from "next/link";
import { Toggle } from "react-powerplug";
import getGovlaunchBaseUrl from "~/lib/utils/getGovlaunchBaseUrl";
import { FormattedMessage } from "react-intl";

interface IProjectPendingVerificationsProps {
  project: Pick<IProjectFieldsFragment, "_id" | "slug" | "locationsPendingVerification" | "viewerCanEdit">;
}

export default function ProjectPendingVerifications({ project }: IProjectPendingVerificationsProps) {
  if (project.locationsPendingVerification.length < 1 || !project.viewerCanEdit) {
    return null;
  }

  return (
    <div
      css={{
        display: "grid",
        gridRowGap: 16,
        padding: "24px 16px",
        backgroundColor: "#EBF4FA",
      }}
    >
      <Heading fontSize="md" color="blue.600" fontWeight="semibold">
        <FormattedMessage defaultMessage="Invitations pending for these governments..." id="XpVxZk" />
      </Heading>

      <CancelVerificationRequest projectId={project._id}>
        {({ cancelVerificationRequest, loading, error }) => {
          const viewerCanRemove = project.locationsPendingVerification.length > 1;

          return (
            <>
              <Box display="flex" flexWrap="wrap" justifyContent={["center", "center", "flex-start"]}>
                {project.locationsPendingVerification.map(location => {
                  return (
                    <Link
                      key={location._id}
                      href={`/government?governmentSlug=${location.slug}`}
                      as={`/governments/${location.slug}`}
                      passHref={true}
                    >
                      <a
                        css={{
                          position: "relative",
                          display: "inline-flex",
                          alignItems: "center",
                          color: "unset",
                          textDecoration: "none",
                          marginRight: 16,
                          marginBottom: 16,
                        }}
                      >
                        {viewerCanRemove && (
                          <button
                            type="button"
                            disabled={loading}
                            css={{
                              position: "absolute",
                              top: -5,
                              right: -5,
                              zIndex: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: 20,
                              height: 20,
                              color: "#fff",
                              fontWeight: 600,
                              fontSize: 18,
                              background: "red",
                              border: "none",
                              borderRadius: "100%",
                              outline: "none",
                              cursor: "pointer",
                              pointerEvents: loading ? "none" : "all",
                              WebkitAppearance: "none",
                            }}
                            onClick={event => {
                              event.preventDefault();
                              cancelVerificationRequest(location._id);
                            }}
                          >
                            &times;
                          </button>
                        )}
                        <Tooltip label={location.name} bg="blue.600" px={2}>
                          <Box position="relative">
                            <Avatar size="lg" src={location.logo} name={location.name} />
                            <Box position="absolute" bottom={1} right={-3}>
                              <CountryFlag countryCode={location.city.country as TCountryCode} width={24} height={14} />
                            </Box>
                          </Box>
                        </Tooltip>
                      </a>
                    </Link>
                  );
                })}
              </Box>

              {error && (
                <Text color="red.600" fontWeight="semibold" fontSize="sm">
                  <FormattedMessage defaultMessage="Couldn't process your request. Try again later in a few minutes." id="Bz/cF8" />
                </Text>
              )}
            </>
          );
        }}
      </CancelVerificationRequest>

      <Toggle initial={false}>
        {({ on, set }) => {
          if (on) {
            return (
              <div>
                <div
                  css={{
                    display: "grid",
                    gridRowGap: 16,
                  }}
                >
                  <p
                    css={{
                      margin: 0,
                      fontSize: 14,
                      lineHeight: 1.33,
                      color: "#0071BB",
                      fontWeight: 500,
                      letterSpacing: 0.8,
                    }}
                  >
                    <FormattedMessage defaultMessage="Send this project link to collaborators who will be able to confirm their involvement" id="8ZOnKn" />
                  </p>

                  <input
                    value={`${getGovlaunchBaseUrl()}/projects/${project.slug}`}
                    onChange={() => {}}
                    readOnly={true}
                    onFocus={event => {
                      event.target.select();
                    }}
                    css={{
                      boxSizing: "border-box",
                      padding: 12,
                      color: "#48A0DA",
                      fontWeight: 500,
                      fontSize: 14,
                      textAlign: "center",
                      fontFamily: "Courier",
                      backgroundColor: "transparent",
                      border: `dashed 1px #48A0DA`,
                      borderRadius: 4,
                      outline: "none",
                      WebkitAppearance: "none",
                    }}
                  />
                </div>
              </div>
            );
          }

          return (
            <Button
              rounded="lg"
              onClick={() => set(true)}
              variant="solid"
              variantColor="blue"
              mx="auto"
              maxWidth="300px"
            >
              <FormattedMessage defaultMessage="Invite Governments Now" id="bXCN64" />
            </Button>
          );
        }}
      </Toggle>
    </div>
  );
}
