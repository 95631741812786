import { useMutation, useQuery } from "@apollo/client";
import React, { ReactNode, ComponentType, forwardRef, useState } from "react";
import {
  Box,
  Flex,
  Avatar,
  Text,
  IconButton,
  Button,
  Stack,
  BoxProps,
  LoadingSpinner,
  CountryFlag,
  Tooltip,
  TCountryCode,
} from "@govlaunch/web";
import moment from "moment";
import { convertFromRaw, convertToRaw, ContentState } from "draft-js";
import { UpvoteIcon, ReplyIcon, EditIcon, DeleteIcon, UpvotedIcon } from "~/src/components/comments/icons";
import { ICommentFieldsFragment } from "~/src/components/comments/graphql/fragments/__generated__/CommentFieldsFragment.generated";
import { useSelfie } from "~/components/auth/Auth";
import WriteCommentMutation from "~/src/components/comments/graphql/WriteCommentMutation";
import {
  IWriteCommentMutation,
  IWriteCommentMutationVariables,
} from "~/src/components/comments/graphql/__generated__/WriteCommentMutation.generated";
import { ICommentTargetType, IUpvoteTargetType } from "~/types/types";
import CommentsListQuery from "~/src/components/comments/graphql/CommentsListQuery";
import { ICommentsListQuery, ICommentsListQueryVariables } from "~/src/components/comments/graphql/__generated__/CommentsListQuery.generated";
import ReplyCommentMutation from "~/src/components/comments/graphql/ReplyCommentMutation";
import {
  IReplyCommentMutation,
  IReplyCommentMutationVariables,
} from "~/src/components/comments/graphql/__generated__/ReplyCommentMutation.generated";
import {
  IEditCommentMutation,
  IEditCommentMutationVariables,
} from "~/src/components/comments/graphql/__generated__/EditCommentMutation.generated";
import EditCommentMutation from "~/src/components/comments/graphql/EditCommentMutation";
import {
  IToggleUpvoteMutation,
  IToggleUpvoteMutationVariables,
} from "~/lib/mutations/__generated__/ToggleUpvoteMutation.generated";
import ToggleUpvoteMutation from "~/lib/mutations/ToggleUpvoteMutation";
import {
  WriteCommentForm,
  ReplyCommentForm,
  EditCommentForm,
  ICommentFormProps,
  ICommentFormValues,
} from "~/src/components/comments/CommentForms";
import {
  IDeleteCommentMutation,
  IDeleteCommentMutationVariables,
} from "~/src/components/comments/graphql/__generated__/DeleteCommentMutation.generated";
import DeleteCommentMutation from "~/src/components/comments/graphql/DeleteCommentMutation";
import { FormApi } from "final-form";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import IntlRelativeTime from "~/src/shared/components/IntlRelativeTime";

interface ICommentsListProps {
  targetId: string;
  targetType: ICommentTargetType;
  viewerCanComment?: boolean;
}

export default function CommentsList({ targetId, targetType, viewerCanComment = true }: ICommentsListProps) {
  const [writeComment] = useMutation<IWriteCommentMutation, IWriteCommentMutationVariables>(WriteCommentMutation);
  const [editComment] = useMutation<IEditCommentMutation, IEditCommentMutationVariables>(EditCommentMutation);
  const [replyComment] = useMutation<IReplyCommentMutation, IReplyCommentMutationVariables>(ReplyCommentMutation);
  const [deleteComment] = useMutation<IDeleteCommentMutation, IDeleteCommentMutationVariables>(DeleteCommentMutation);
  const [toggleUpvote] = useMutation<IToggleUpvoteMutation, IToggleUpvoteMutationVariables>(ToggleUpvoteMutation);
  const { data, loading } = useQuery<ICommentsListQuery, ICommentsListQueryVariables>(CommentsListQuery, {
    variables: {
      targetId,
      targetType,
      cursor: null,
    },
  });

  if (!viewerCanComment && data?.comments?.items.length === 0 && !loading) {
    return null;
  }

  return (
    <Box bg="gray.100" borderRadius="sm" py={4} px={[1, 8]}>
      {viewerCanComment && (
        <WriteCommentForm
          onSubmit={async ({ body }, form) => {
            await writeComment({
              variables: {
                body: convertToRaw(ContentState.createFromText(body)),
                targetId,
                targetType,
              },
            });

            setTimeout(form.reset);

            return null;
          }}
        />
      )}

      {loading && <LoadingSpinner />}
      {!loading && (data?.comments?.items || []).length > 0 && (
        <Box
          mt={4}
          px={[1, 2]}
          py={4}
          bg="white"
          css={{
            ".comment:last-child .comment__divider": {
              display: "none",
            },
            ".comment:not(:first-of-type)": {
              marginTop: 12,
            },
          }}
        >
          {data?.comments?.items
            .sort((a, b) => sortComments(a, b))
            .map(comment => {
              return (
                <Comment
                  key={comment._id}
                  comment={comment}
                  viewerCanComment={viewerCanComment}
                  onToggleUpvote={comment => {
                    toggleUpvote({
                      variables: {
                        targetType: IUpvoteTargetType.Comment,
                        targetId: comment._id,
                      },
                    });
                  }}
                  onReply={async ({ body }) => {
                    await replyComment({
                      variables: {
                        body: convertToRaw(ContentState.createFromText(body)),
                        replyTo: comment._id,
                      },
                    });

                    return null;
                  }}
                  onEdit={async (targetComment, { body }) => {
                    await editComment({
                      variables: {
                        body: convertToRaw(ContentState.createFromText(body)),
                        commentId: targetComment._id,
                      },
                    });
                  }}
                  onDelete={comment => {
                    deleteComment({
                      variables: {
                        commentId: comment._id,
                        targetId,
                      },
                    });
                  }}
                />
              );
            })}
        </Box>
      )}
    </Box>
  );
}

interface ICommentProps {
  comment: ICommentFieldsFragment;
  onReply?: ICommentFormProps["onSubmit"];
  onEdit: (comment: ICommentFieldsFragment, values: ICommentFormValues, form: FormApi<ICommentFormValues>) => void;
  onDelete: (comment: ICommentFieldsFragment) => void;
  onToggleUpvote: (comment: ICommentFieldsFragment) => void;
  viewerCanComment?: boolean;
}

function Comment({
  comment,
  viewerCanComment = true,
  onReply,
  onEdit,
  onDelete,
  onToggleUpvote,
  ...props
}: ICommentProps & BoxProps) {
  const viewer = useSelfie();
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const viewerIsAuthor = viewer && viewer._id === comment.author._id;

  return (
    <Box className="comment" {...props}>
      {comment.author.slug ? (
        <Link href={`/user?userSlug=${comment.author.slug}`} as={`/@${comment.author.slug}`} passHref={true}>
          <CommentAuthor comment={comment} />
        </Link>
      ) : (
        <CommentAuthor comment={comment} />
      )}

      <Box mt={3} pl={12}>
        {isEditing ? (
          <EditCommentForm
            initialValues={{
              body: getCommentText(comment.body),
            }}
            onSubmit={async (values, form) => {
              await onEdit(comment, values, form);

              setIsEditing(false);
            }}
          />
        ) : (
          <Text color="gray.900" fontSize="sm">
            {getCommentText(comment.body)}
          </Text>
        )}

        <Stack spacing={[3, 5]} direction="row" mt={2}>
          <IconButton
            aria-label="upvote"
            icon={comment.viewerDidUpvote ? UpvotedIcon : UpvoteIcon}
            minWidth="auto"
            size="sm"
            onClick={() => onToggleUpvote(comment)}
          />

          {onReply && viewerCanComment && (
            <CommentToolbarButton icon={ReplyIcon} onClick={() => setIsReplying(!isReplying)}>
              <FormattedMessage defaultMessage="Reply" id="9HU8vw" />
            </CommentToolbarButton>
          )}

          {viewerIsAuthor && (
            <CommentToolbarButton icon={EditIcon} onClick={() => setIsEditing(!isEditing)}>
              <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
            </CommentToolbarButton>
          )}

          {viewerIsAuthor && (
            <CommentToolbarButton icon={DeleteIcon} onClick={() => onDelete(comment)}>
              <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
            </CommentToolbarButton>
          )}
        </Stack>

        {isReplying && onReply && (
          <Box my={2}>
            <ReplyCommentForm
              onSubmit={async (values, form) => {
                await onReply(values, form);

                setIsReplying(false);
              }}
            />
          </Box>
        )}

        {comment.isReply && (
          <Box height="1px" width="100%" bg="gray.100" pl={12} mt={2} className="comment__reply__divider" />
        )}

        {!comment.isReply && comment.replies.length === 0 && (
          <Box height="1px" width="100%" bg="gray.100" pl={12} mt={2} className="comment__divider" />
        )}
      </Box>

      {comment.replies.length > 0 && (
        <Box
          borderLeft="2px"
          borderLeftColor="blue.600"
          pl={4}
          ml={[8, 8, 12]}
          mt={6}
          css={{
            ".reply:last-child .comment__reply__divider": {
              display: "none",
            },
            ".reply:not(:first-of-type)": {
              marginTop: 16,
            },
          }}
        >
          {comment.replies.map(reply => {
            return (
              <Comment
                comment={{
                  ...reply,
                  replies: [],
                }}
                key={reply._id}
                className="reply"
                onDelete={onDelete}
                onEdit={onEdit}
                onToggleUpvote={onToggleUpvote}
                onReply={onReply}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}

interface ICommentAuthorProps {
  comment: ICommentFieldsFragment;
}

const CommentAuthor = forwardRef<HTMLAnchorElement, ICommentAuthorProps>(({ comment, ...props }, ref) => {
  return (
    <Flex as="a" align="center" {...props} ref={ref}>
      <Avatar src={comment.author.avatar} name={comment.author.fullName || comment.author.firstName} size="sm" />
      <Box ml={[2, 2, 4]}>
        <Text color="gray.900" fontSize="md">
          {comment.author.fullName || comment.author.firstName}
          <Text as="span" fontSize="xs" color="gray.500" ml={2}>
            &bull; <IntlRelativeTime value={new Date(comment.lastUpdatedAt || comment.createdAt)} />
          </Text>
        </Text>

        <Box display="flex" alignItems="center">
          <Text color="gray.500" fontSize="xs">
            {comment.author.__typename === "PendingUser" ? comment.author.jobTitle : comment.author.title}

            {comment.author.__typename === "GovernmentUser" && (
              <Tooltip bg="blue.600" label={comment.author.government.city.countryName}>
                <Box display="inline-flex" ml={1} as="span" verticalAlign="top">
                  <CountryFlag
                    width={29}
                    height={14}
                    countryCode={comment.author.government.city.country as TCountryCode}
                  />
                </Box>
              </Tooltip>
            )}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
});

CommentAuthor.displayName = "CommentAuthor";

interface ICommentToolbarButtonProps {
  icon: ComponentType;
  children: ReactNode;
  onClick?: () => void;
}

function CommentToolbarButton({ icon, children, ...props }: ICommentToolbarButtonProps) {
  return (
    <Button leftIcon={icon} color="gray.400" px={0} fontWeight="normal" size="sm" {...props}>
      {children}
    </Button>
  );
}

function getCommentText(rawCommentBody: any) {
  const contentState = convertFromRaw(rawCommentBody);

  return contentState
    .getBlocksAsArray()
    .map(block => {
      const text = block.getText().trim();

      return text || "\n";
    })
    .join("\n");
}

function sortComments(a: any, b: any) {
  const dateA = moment(a.createdAt).format("YYYY-MM-DD hh:mm:ss");
  const dateB = moment(b.createdAt).format("YYYY-MM-DD hh:mm:ss");

  if (dateA < dateB) {
    return 1;
  }

  if (dateA > dateB) {
    return -1;
  }

  return 0;
}
