import React from "react";

const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={17} height={18} fill="red" {...props}>
    <defs>
      <linearGradient id="prefix__a" x1="49.995%" x2="49.995%" y1="2510.674%" y2="-1191.051%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
      <linearGradient id="prefix__b" x1="50.002%" x2="50.002%" y1="2510.674%" y2="-1191.051%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
      <linearGradient id="prefix__c" x1="49.999%" x2="49.999%" y1="2510.674%" y2="-1191.051%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
      <linearGradient id="prefix__d" x1="50%" x2="50%" y1="2070.146%" y2="-1631.579%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
      <linearGradient id="prefix__e" x1="49.999%" x2="49.999%" y1="2070.146%" y2="-1631.579%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
      <linearGradient id="prefix__f" x1="50%" x2="50%" y1="1602.145%" y2="-2099.58%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
      <linearGradient id="prefix__g" x1="49.997%" x2="49.997%" y1="1169.626%" y2="-2532.099%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
      <linearGradient id="prefix__h" x1="50%" x2="50%" y1="95.985%" y2="3.942%">
        <stop offset="32.2%" stopColor="#A163F5" />
        <stop offset="46.6%" stopColor="#B074EE" />
        <stop offset="75.2%" stopColor="#D8A1DD" />
        <stop offset="89.8%" stopColor="#EFBAD3" />
      </linearGradient>
    </defs>
    <g fill="none">
      <path fill="url(#prefix__a)" d="M10.093 6.483h.96a.224.224 0 1 0 .001-.448h-.961a.224.224 0 0 0 0 .447z" />
      <path
        fill="url(#prefix__b)"
        d="M5.86 6.483h3.371a.224.224 0 0 0 0-.447h-3.37c-.125 0-.226.1-.226.223s.101.224.225.224z"
      />
      <path
        fill="url(#prefix__c)"
        d="M3.548 6.483h1.433c.125 0 .226-.1.226-.224s-.101-.224-.226-.224H3.548a.224.224 0 0 0 0 .447z"
      />
      <path
        fill="url(#prefix__d)"
        d="M8.696 8.005H4.704c-.125 0-.226.1-.226.224 0 .123.101.224.226.224h3.992a.224.224 0 1 0 0-.448z"
      />
      <path fill="url(#prefix__e)" d="M3.548 8.453h.418a.224.224 0 1 0 0-.448h-.418a.224.224 0 0 0 0 .447z" />
      <path
        fill="url(#prefix__f)"
        d="M3.548 10.546h2.674a.224.224 0 0 0 0-.447H3.548c-.124 0-.225.1-.225.223s.1.224.225.224z"
      />
      <path fill="url(#prefix__g)" d="M4.629 12.033H3.548a.224.224 0 0 0 0 .447h1.08a.224.224 0 0 0 .001-.447z" />
      <path
        fill="url(#prefix__h)"
        d="M16.597 4.953a.966.966 0 0 0-1.33-.273l-.245.16h-.001l-.43.28V1.832a.959.959 0 0 0-.962-.954H8.7A1.55 1.55 0 0 0 7.302 0a1.55 1.55 0 0 0-1.399.877H.972c-.53 0-.96.427-.96.954v15.2c0 .527.43.954.96.954H13.63c.53 0 .96-.427.961-.954V7.878l1.95-1.272a.951.951 0 0 0 .276-1.322l-.219-.33zm-8.359 6.535l-.266-.403 7.622-4.973.266.402-7.622 4.974zm4.195 1.938H11.25c-.53 0-.96.427-.961.954v1.146H2.31a.143.143 0 0 1-.143-.142V3.479c0-.079.064-.142.143-.142h1.012v.613c0 .124.1.224.225.224h7.506c.124 0 .225-.1.225-.224v-.613h1.012c.078 0 .142.063.142.142v3.049l-5.41 3.53a.163.163 0 0 0-.016.012.217.217 0 0 0-.037.037l-.004.005a.208.208 0 0 0-.012.018l-.002.003-1.023 1.75-.304.199a.223.223 0 0 0-.065.31.226.226 0 0 0 .312.063l.304-.198L8.2 12.02l.004-.001.01-.002a.207.207 0 0 0 .077-.03l.006-.003 4.136-2.698v4.14zm-.309.447l-1.386 1.377v-.87a.51.51 0 0 1 .51-.507h.876zm-4.338-2.255l-1.268.149.64-1.097.314.474.314.474zm-.061-.906l-.266-.403 7.032-4.588.59-.385.266.402-7.622 4.974zm5.904-9.388a.51.51 0 0 1 .511.507v3.583l-1.256.82V3.48a.592.592 0 0 0-.593-.59h-1.012v-.692c0-.124-.1-.224-.225-.224H8.85V1.54a1.55 1.55 0 0 0-.016-.215h4.794zM7.301.447c.607 0 1.099.49 1.1 1.092v.658c0 .123.1.223.224.223h2.204v1.307H3.773V2.42h2.203c.125 0 .225-.1.225-.223v-.658a1.098 1.098 0 0 1 1.1-1.092zm6.839 16.584a.51.51 0 0 1-.51.507H.971a.51.51 0 0 1-.51-.507v-15.2a.51.51 0 0 1 .51-.507h4.794a1.55 1.55 0 0 0-.015.215v.434H3.548c-.124 0-.225.1-.225.224v.692H2.31a.592.592 0 0 0-.593.59v11.905c0 .325.266.588.593.589h8.06a.7.7 0 0 0 .088-.007.225.225 0 0 0 .223-.07.67.67 0 0 0 .16-.117l1.846-1.834a.656.656 0 0 0 .196-.468V8.992l1.256-.82v8.86zm2.153-10.799l-.056.037-.78-1.179.056-.036a.513.513 0 0 1 .708.145l.218.33a.506.506 0 0 1-.146.703z"
      />
    </g>
  </svg>
);

export default CopyIcon;
