import { Button } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import MakersProIcon from "~/components/icons/MakersProIcon";
import { Margin } from "~/components/spacings";

export default function UpgradeToMakersProBanner() {
  return (
    <div
      css={{
        borderRadius: 4,
        border: `1px solid ${palette.lightestGray}`,
        background: "#fff",
        textAlign: "center",
        padding: 32,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <MakersProIcon width={80} height={80} />

      <h1
        css={{
          fontSize: 36,
          fontWeight: 800,
          margin: "8px 0 8px",
          color: palette.text,
        }}
      >
        <FormattedMessage defaultMessage="Upgrade to {plan}" id="/gko4g" values={{ plan: "Makers Pro" }} />
      </h1>

      <div
        css={{
          color: palette.mediumGray,
          textAlign: "center",
          marginBottom: 24,
          maxWidth: 500,
          fontSize: 18,
        }}
      >
        <FormattedMessage defaultMessage="Unlock more tools to showcase your solutions and the ability to search other vendors and products." id="iBvgld" />
      </div>

      <div>
        <img
          src="/static/makers/makers_pro_gating_15.png"
          alt="Upgrade to Makers Pro"
          width={600}
          css={{
            objectFit: "contain",
          }}
        />
      </div>

      <Button
        size="large"
        theme="primary"
        color={palette.greenSuccess}
        textColor={palette.white}
        css={{ minWidth: 300, marginTop: 24 }}
        data-intercom-target="ContactUsForMakersPro"
      >
        <FormattedMessage defaultMessage="Contact Us" id="hZOGLS" />
      </Button>

      <Margin mt={12}>
        <Link href="/makers" passHref={true}>
          <a
            css={{
              color: palette.innovatorBlue,
              fontSize: 14,
            }}
          >
            <FormattedMessage defaultMessage="Learn more about" id="Clvypl" />{" "}
            <span
              css={{
                fontWeight: 600,
              }}
            >
              Makers Pro
            </span>
          </a>
        </Link>
      </Margin>
    </div>
  );
}
