import React, { ReactElement, useState } from "react";
import { IProjectFieldsFragment, IProjectFieldsGroupProjectFragment } from "@govlaunch/project";
import GroupLinkGatedContent from "~/components/group/GroupLinkGatedContent";
import Link from "next/link";
import { useSelfie } from "~/components/auth/Auth";
import { IGroupKind, IGroupVisibility } from "~/types/types";
import { FormattedMessage } from "react-intl";

interface IProjectGroupLinkProps {
  project: IProjectFieldsFragment["groups"][0] | IProjectFieldsGroupProjectFragment["group"];
  children: ReactElement;
}

export default function ProjectGroupLink({ project, children }: IProjectGroupLinkProps) {
  const [showGate, setShowGate] = useState(false);
  const user = useSelfie();

  const isNotMemberToPrivateGroup = project.visibility === IGroupVisibility.Private && project.viewerIsMember === false;
  const isPendingUser =
    project.visibility === IGroupVisibility.Public && user?.__typename === "PendingUser" && user.verified === false;
  const isVendorUserGate = project.kind === IGroupKind.Discussion && user?.__typename === "VendorUser";

  if (isNotMemberToPrivateGroup || isPendingUser || isVendorUserGate) {
    const message = getGroupGateMessage(isNotMemberToPrivateGroup, isPendingUser, isVendorUserGate);

    return (
      <>
        <GroupLinkGatedContent isOpen={showGate} message={message} onClose={() => setShowGate(false)} />
        <div
          onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
            setShowGate(true);
            event.preventDefault();
          }}
        >
          {children}
        </div>
      </>
    );
  }

  if (project.viewerIsMember || project.visibility === IGroupVisibility.Public) {
    return (
      <Link href={`/group?groupSlug=${project.slug}`} as={`/groups/${project.slug}`} passHref={true} key={project._id}>
        {children}
      </Link>
    );
  }

  return children;
}

function getGroupGateMessage(isNotMemberToPrivateGroup: boolean, isPendingUser: boolean, isVendorUserGate: boolean) {
  if (isNotMemberToPrivateGroup) {
    return <FormattedMessage defaultMessage="You must be a member to view this group." id="cpiboW" />;
  }

  if (isPendingUser) {
    return <FormattedMessage defaultMessage="You must be a verified user to view this group." id="pCWodn" />;
  }

  if (isVendorUserGate) {
    return <FormattedMessage defaultMessage="You must be a government user to view this group." id="0FRC5l" />;
  }

  return <FormattedMessage defaultMessage="Cannot open group profile." id="nrXtIc" />;
}
