import { gql } from "@apollo/client";
import { ProjectFieldsFragment } from "@govlaunch/project";

export default gql`
  query ProjectBySlug($slug: String!, $token: String, $language: String) {
    projectBySlug(slug: $slug, language: $language) {
      ...ProjectFields

      viewerCanRead
      viewerCanView
      updatedAt

      viewerCanInviteCollaborators
      viewerCanRemoveCollaborators
      viewerCanComment
      collaborators(token: $token) {
        ... on GovernmentUser {
          _id
          slug
          fullName
          avatar
          jobTitle
        }
        ... on VendorUser {
          _id
          slug
          fullName
          avatar
          jobTitle
        }
        ... on PendingUser {
          _id
          slug
          fullName
          avatar
          jobTitle
        }
      }
    }
  }

  ${ProjectFieldsFragment}
`;
