import { gql } from "@apollo/client";
import { ProjectFieldsFragment } from "@govlaunch/project";

export default gql`
  mutation CancelVerificationRequestFromProjectByGovernmentId($projectId: ObjectId!, $governmentId: ObjectId!) {
    cancelVerificationRequestFromProjectByGovernmentId(projectId: $projectId, governmentId: $governmentId) {
      _id
      ...ProjectFields
    }
  }

  ${ProjectFieldsFragment}
`;
