import { Avatar, Box, Button, ExpandingTextarea, Flex } from "@govlaunch/web";
import { FormApi } from "final-form";
import React, { FC } from "react";
import { Field, Form } from "react-final-form";
import * as Yup from "yup";
import { useSelfie } from "~/components/auth/Auth";
import validate from "~/lib/utils/validate";
import { FormattedMessage, useIntl } from "react-intl";

export interface ICommentFormValues {
  body: string;
}

export interface ICommentFormProps {
  onSubmit: (values: ICommentFormValues, form: FormApi<ICommentFormValues>) => void;
  initialValues?: ICommentFormValues;
}

const validationSchema = Yup.object().shape({
  body: Yup.string().required().trim(),
});

const FormBox = Box.withComponent("form");

export const WriteCommentForm: FC<ICommentFormProps> = ({ onSubmit }) => {
  const viewer = useSelfie();
  const intl = useIntl();

  if (!viewer) {
    return null;
  }

  return (
    <Form<ICommentFormValues> onSubmit={onSubmit} validate={validate(validationSchema)}>
      {({ handleSubmit, valid, submitting }) => (
        <FormBox display="flex" flexDirection="column" as="form" onSubmit={handleSubmit}>
          <Flex>
            <Box mr={2}>
              <Avatar src={viewer.avatar} name={viewer.fullName} size="sm" />
            </Box>
            <Field name="body">
              {({ input }) => (
                <ExpandingTextarea
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Write your comment...', id: '6jOrmJ',
                  })}
                  size="sm"
                  {...input}
                  aria-label={intl.formatMessage({
                    defaultMessage: 'Write your comment...', id: '6jOrmJ',
                  })}
                />
              )}
            </Field>
          </Flex>
          {valid && (
            <Flex justify="flex-end" mt={3}>
              <Button
                loading={submitting}
                loadingText={intl.formatMessage({
                  defaultMessage: 'Commenting...', id: 'rEm9aW',
                })}
                type="submit"
                variant="primary"
                size="sm"
              >
                <FormattedMessage defaultMessage="Comment" id="LgbKvU" />
              </Button>
            </Flex>
          )}
        </FormBox>
      )}
    </Form>
  );
};

export const ReplyCommentForm: FC<ICommentFormProps> = ({ onSubmit }) => {
  const viewer = useSelfie();
  const intl = useIntl();

  if (!viewer) {
    return null;
  }

  return (
    <Form onSubmit={onSubmit} validate={validate(validationSchema)}>
      {({ handleSubmit, valid, submitting }) => (
        <FormBox display="flex" flexDirection="column" as="form" onSubmit={handleSubmit}>
          <Flex>
            <Box mr={2}>
              <Avatar src={viewer.avatar} name={viewer.fullName} size="sm" />
            </Box>
            <Field name="body">{({ input }) => <ExpandingTextarea autoFocus={true} size="sm" {...input} />}</Field>
          </Flex>
          <Flex justify="flex-start" mt={3}>
            <Button
              disabled={!valid}
              loading={submitting}
              loadingText={intl.formatMessage({
                defaultMessage: 'Replying...', id: 'x4bZQK',
              })}
              type="submit"
              variant="primary"
              size="sm"
              ml={10}
            >
              <FormattedMessage defaultMessage="Reply" id="9HU8vw" />
            </Button>
          </Flex>
        </FormBox>
      )}
    </Form>
  );
};

export const EditCommentForm: FC<ICommentFormProps> = ({ onSubmit, initialValues }) => {
  const intl = useIntl();

  return (
    <Form<ICommentFormValues> onSubmit={onSubmit} validate={validate(validationSchema)} initialValues={initialValues}>
      {({ handleSubmit, valid, submitting }) => (
        <FormBox display="flex" flexDirection="column" as="form" onSubmit={handleSubmit}>
          <Field name="body">{({ input }) => <ExpandingTextarea autoFocus={true} size="sm" {...input} />}</Field>

          <Flex justify="flex-start" mt={3}>
            <Button
              disabled={!valid}
              loading={submitting}
              loadingText={intl.formatMessage({
                defaultMessage: 'Editing...', id: '6CJjWk',
              })}
              type="submit"
              variant="primary"
              size="sm"
            >
              <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
            </Button>
          </Flex>
        </FormBox>
      )}
    </Form>
  );
};
