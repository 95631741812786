import { gql } from "@apollo/client";

export default gql`
  fragment CommentFields on Comment {
    _id
    author {
      ... on GovernmentUser {
        _id
        avatar
        firstName
        fullName
        title
        slug
        government {
          _id
          city {
            _id
            country
            countryName
          }
        }
      }
      ... on VendorUser {
        _id
        avatar
        firstName
        fullName
        title
        slug
      }
      ... on PendingUser {
        _id
        avatar
        firstName
        fullName
        jobTitle
        slug
      }
    }
    body
    createdAt
    lastUpdatedAt
    viewerDidUpvote
    isReply
    replies {
      _id
      author {
        ... on GovernmentUser {
          _id
          avatar
          firstName
          fullName
          title
          slug
          government {
            _id
            city {
              _id
              country
              countryName
            }
          }
        }
        ... on VendorUser {
          _id
          avatar
          firstName
          fullName
          title
          slug
        }
        ... on PendingUser {
          _id
          avatar
          firstName
          fullName
          jobTitle
          slug
        }
      }
      body
      createdAt
      lastUpdatedAt
      viewerDidUpvote
      isReply
    }
  }
`;
