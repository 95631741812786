import { TProjectObjectLink } from "@govlaunch/project";
import Link from "next/link";
import { NextRouter } from "next/router";
import React, { ReactElement } from "react";
import { appendNextLinkHrefParams } from "~/src/utils/nextLinkHref";
import ProjectGroupLink from "~/src/components/project/ProjectGroupLink";

const createProjectLinkWrap = (router: NextRouter) => (entity: TProjectObjectLink, children: ReactElement) => {
  if (!entity) {
    return children;
  }

  if (entity.__typename === "Product") {
    return (
      <Link href={`/products/${entity.slug}`} passHref={true} key={entity._id}>
        {children}
      </Link>
    );
  }

  if (entity.__typename === "Company") {
    return (
      <Link href={`/vendor?vendorSlug=${entity.slug}`} as={`/vendors/${entity.slug}`} passHref={true} key={entity._id}>
        {children}
      </Link>
    );
  }

  if (entity.__typename === "Government") {
    return (
      <Link
        href={`/government?governmentSlug=${entity.slug}`}
        as={`/governments/${entity.slug}`}
        passHref={true}
        key={entity._id}
      >
        {children}
      </Link>
    );
  }

  if (entity.__typename === "Group") {
    return (
      <ProjectGroupLink project={entity} key={entity._id}>
        {children}
      </ProjectGroupLink>
    );
  }

  if (
    entity.__typename === "GovernmentProject" ||
    entity.__typename === "VendorProject" ||
    entity.__typename === "GroupProject"
  ) {
    const linkHref = appendNextLinkHrefParams(router, {
      projectSlug: entity.slug,
    });

    return (
      <Link href={linkHref} as={`/projects/${entity.slug}`} passHref={true} key={entity._id}>
        {children}
      </Link>
    );
  }

  if (
    entity.__typename === "PendingUser" ||
    entity.__typename === "VendorUser" ||
    entity.__typename === "GovernmentUser"
  ) {
    return (
      <Link href={`/user?userSlug=${entity.slug}`} as={`/@${entity.slug}`} passHref={true} key={entity._id}>
        {children}
      </Link>
    );
  }

  return children;
};

export default createProjectLinkWrap;
