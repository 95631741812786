import { gql } from "@apollo/client";

export default gql`
  mutation ToggleBookmark($targetId: ObjectId!, $targetType: BookmarkType!) {
    toggleBookmark(targetId: $targetId, targetType: $targetType) {
      __typename
      ... on Product {
        _id
        viewerDidBookmark
      }
      ... on Story {
        _id
        viewerDidBookmark
      }
      ... on Project {
        _id
        viewerDidBookmark
      }
      ... on GovernmentPost {
        _id
        viewerDidBookmark
      }
      ... on GroupPost {
        _id
        viewerDidBookmark
      }
      ... on VendorPost {
        _id
        viewerDidBookmark
      }
      ... on Collection {
        _id
        viewerDidBookmark
      }
    }
  }
`;
