import React, { ReactElement, ReactNode } from "react";

import DelayedHover from "~/components/DelayedHover";
import Popover from "@govlaunch/popover";

interface IHoverState {
  hover: boolean;
  innerRef: (ref: ReactNode) => void;
  bind: {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}

interface ITooltipProps {
  children: (state: IHoverState) => ReactElement | null;
  [key: string]: any;
}

export default function Tooltip({ children, ...props }: ITooltipProps) {
  return (
    <DelayedHover ms={200}>
      {({ hover, onMouseEnter, onMouseLeave }) => (
        <Popover
          placement="top"
          isOpen={hover}
          {...props}
          boxStyle={{
            ...(props.boxStyle || {}),
            zIndex: 1050,
          }}
        >
          {({ innerRef }: { innerRef: (ref: ReactNode) => void }) =>
            children({
              hover,
              bind: {
                onMouseEnter,
                onMouseLeave,
              },
              innerRef,
            })
          }
        </Popover>
      )}
    </DelayedHover>
  );
}
