import { Mutation } from "@apollo/client/react/components";
import styled from "@emotion/styled";
import { Button, Input } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@govlaunch/web";
import noop from "lodash/fp/noop";
import React, { FunctionComponent, ReactElement, useState } from "react";
import { Field, Form } from "react-final-form";
import * as Yup from "yup";
import { Margin } from "~/components/spacings";
import useClipboard from "~/lib/hooks/useClipboard";
import GetInviteToken from "~/lib/mutations/GetInviteToken";
import SendInvites from "~/lib/mutations/SendInvites";
import {
  IGetInviteTokenMutation,
  IGetInviteTokenMutationVariables,
} from "~/lib/mutations/__generated__/GetInviteToken.generated";
import {
  ISendInvitesMutation,
  ISendInvitesMutationVariables,
} from "~/lib/mutations/__generated__/SendInvites.generated";
import validate from "~/lib/utils/validate";
import { useSelfie } from "~/components/auth/Auth";
import PlusIcon from "~/components/icons/PlusIcon";
import CopyIcon from "~/components/invite/components/CopyIcon";
import LinkIcon from "~/components/invite/components/LinkIcon";
import { FormattedMessage, useIntl } from "react-intl";

interface IInviteModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  title: string;
  subtitle: string | ReactElement;
  redirectTo?: string;
  targetType: string;
  targetId: string;
  inviteByEmail?: boolean;
}

function getInviteLink(token: string, redirectTo?: string): string {
  const href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  if (redirectTo) {
    return `${href}?token=${token}&redirectTo=${redirectTo}`;
  }

  return `${href}?token=${token}`;
}

const InviteModal: FunctionComponent<IInviteModalProps> = ({
  isOpen,
  onRequestClose,
  title,
  subtitle,
  targetType,
  targetId,
  redirectTo,
  inviteByEmail = true,
}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const submitButtonRef = React.createRef<HTMLButtonElement>();
  const [copied, setClipboard] = useClipboard(inputRef);
  const selfie = useSelfie();
  const [error, setError] = useState<string | null>(null);
  const intl = useIntl();

  if (!selfie) {
    return null;
  }

  return (
    <Modal onClose={onRequestClose} isOpen={isOpen} size="md">
      <ModalOverlay zIndex={1401} />
      <ModalContent zIndex={1402} borderRadius="md">
        {onRequestClose && <ModalCloseButton onClick={onRequestClose} zIndex={1} />}

        <ModalBody borderRadius="md" py={4} px={6}>
          <ShareTitle>{title}</ShareTitle>
          <ShareSubtitle>{subtitle}</ShareSubtitle>

          <Mutation<IGetInviteTokenMutation, IGetInviteTokenMutationVariables>
            variables={{
              targetType,
              targetId,
            }}
            mutation={GetInviteToken}
          >
            {(getToken, { data }) => (
              <Margin mt={12}>
                {!data ? (
                  <InviteLinkButton
                    type="button"
                    onClick={() => {
                      if (
                        selfie.__typename === "VendorUser" &&
                        selfie.company &&
                        targetType === "VENDOR" &&
                        !selfie.company.viewerCanInviteMembers
                      ) {
                        setError("Your current plan doesn't support more team members.");

                        return;
                      }

                      getToken();
                    }}
                  >
                    <span
                      css={{
                        marginRight: 5,
                      }}
                    >
                      <FormattedMessage defaultMessage="Create invite link" id="u03Ges" />
                    </span>{" "}
                    <LinkIcon />
                  </InviteLinkButton>
                ) : (
                  <div>
                    <InviteLinkButton type="button" onClick={() => setClipboard()}>
                      <span
                        css={{
                          marginRight: 5,
                        }}
                      >
                        <FormattedMessage defaultMessage="Copy invite link" id="rtACcE" />
                      </span>{" "}
                      <CopyIcon />
                    </InviteLinkButton>

                    {copied && (
                      <span
                        css={{
                          fontSize: 14,
                          color: palette.greenBlue,
                          marginLeft: 8,
                        }}
                      >
                        <FormattedMessage defaultMessage="Copied!" id="0oL1zz" />
                      </span>
                    )}

                    {data.getInviteToken && (
                      <Margin mt={12}>
                        <Input
                          value={getInviteLink(data.getInviteToken, redirectTo)}
                          onChange={noop}
                          onFocus={(e: any) => e.target.select()}
                          size="xsmall"
                          innerRef={inputRef}
                        />
                      </Margin>
                    )}

                    <Margin mt={8}>
                      <p
                        css={{
                          margin: 0,
                          fontSize: 12,
                          color: palette.mediumGray,
                          "& strong": {
                            fontWeight: 500,
                          },
                        }}
                      >
                        <FormattedMessage defaultMessage="This link will expire in 5 days" id="4wf8qW" />
                      </p>
                    </Margin>
                  </div>
                )}
              </Margin>
            )}
          </Mutation>

          {inviteByEmail && (
            <>
              <Margin mt={12}>
                <p
                  css={{
                    fontSize: 14,
                    color: palette.darkGray,
                    margin: 0,
                  }}
                >
                  <FormattedMessage defaultMessage="People you know" id="/GvWeq" />
                </p>
              </Margin>

              <Margin mt={12}>
                <Mutation<ISendInvitesMutation, ISendInvitesMutationVariables> mutation={SendInvites}>
                  {(sendInvites) => (
                    <Form
                      onSubmit={(values) => {
                        setError(null);

                        if (
                          selfie.__typename === "VendorUser" &&
                          selfie.company &&
                          !selfie.company.viewerCanInviteMembers
                        ) {
                          setError(
                            intl.formatMessage({
                              defaultMessage: `Your current plan doesn't support more team members.`,
                              id: "U2MGCV",
                            }),
                          );

                          return;
                        }

                        sendInvites({
                          variables: {
                            targetType,
                            targetId,
                            emails: (values as any).emails.filter(isValidEmail),
                          },
                        });

                        return;
                      }}
                      initialValues={{
                        emails: [""],
                      }}
                      validate={validate(
                        Yup.object().shape({
                          emails: Yup.array().of(
                            Yup.string().email(
                              intl.formatMessage({
                                defaultMessage: "Invalid email address",
                                id: "5WgK3Y",
                              }),
                            ),
                          ),
                        }),
                      )}
                    >
                      {({ handleSubmit, values, form, dirty, invalid, submitSucceeded }) => (
                        <form onSubmit={handleSubmit}>
                          <div
                            css={{
                              display: "grid",
                              rowGap: 8,
                            }}
                          >
                            {values.emails.map((_: any, index: number) => {
                              const isLastField = values.emails.length === index + 1;

                              return (
                                <Field name={`emails[${index}]`} key={index}>
                                  {({ input, meta }) => (
                                    <div>
                                      <div
                                        css={{
                                          display: "grid",
                                          gridAutoFlow: "column",
                                          gridTemplateColumns: isLastField ? "1fr auto" : "1fr",
                                          gridColumnGap: 8,
                                        }}
                                      >
                                        <Input
                                          {...input}
                                          placeholder={intl.formatMessage({
                                            defaultMessage: "Enter email address",
                                            id: "MnA9KQ",
                                          })}
                                          autoComplete="off"
                                          autoFocus={index > 0}
                                        />

                                        {isLastField && (
                                          <AddEmailButton
                                            type="button"
                                            onClick={() => {
                                              if (input.value.trim() !== "" && !meta.error) {
                                                form.change("emails", values.emails.concat(""));
                                              }
                                            }}
                                          >
                                            <PlusIcon />
                                          </AddEmailButton>
                                        )}
                                      </div>
                                      {meta.touched && meta.error && (
                                        <p
                                          css={{
                                            margin: "8px 0 0 0",
                                            color: palette.red,
                                            fontSize: 12,
                                          }}
                                        >
                                          {meta.error}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              );
                            })}
                          </div>

                          {submitSucceeded && !dirty && (
                            <p
                              css={{
                                fontSize: 12,
                                margin: "8px 0 0 0",
                                color: palette.darkGray,
                              }}
                            >
                              <span
                                css={{
                                  fontWeight: 600,
                                  color: palette.greenBlue,
                                }}
                              >
                                <FormattedMessage defaultMessage="Sent!" id="/xwYHP" />
                              </span>{" "}
                              <FormattedMessage
                                defaultMessage="You can also create an invite link above and send it directly to them."
                                id="cOIisR"
                              />
                            </p>
                          )}

                          <Margin
                            mt={12}
                            css={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {dirty ? (
                              <>
                                <Button
                                  color={palette.greenBlue}
                                  textColor={palette.white}
                                  size="small"
                                  tabIndex={1}
                                  type="submit"
                                  padding={8}
                                  disabled={invalid}
                                  css={{
                                    ":disabled": {
                                      opacity: 0.5,
                                    },
                                  }}
                                  innerRef={submitButtonRef}
                                >
                                  <FormattedMessage defaultMessage="Send invite" id="oBB4L4" />
                                </Button>
                                <Button
                                  theme="link"
                                  textColor={palette.red}
                                  type="reset"
                                  size="small"
                                  padding={8}
                                  onClick={form.reset}
                                >
                                  <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
                                </Button>
                              </>
                            ) : (
                              <Button
                                type="button"
                                color={palette.innovatorBlue}
                                size="small"
                                padding="0 8px"
                                height={27}
                                fontWeight={500}
                                onClick={onRequestClose}
                              >
                                <FormattedMessage defaultMessage="Done" id="JXdbo8" />
                              </Button>
                            )}
                          </Margin>
                        </form>
                      )}
                    </Form>
                  )}
                </Mutation>
              </Margin>
            </>
          )}
          {error && (
            <Margin mt={8}>
              <p css={{ margin: 0, color: palette.red, fontSize: 14 }}>{error}</p>
            </Margin>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function isValidEmail(email: string) {
  return Yup.string().email().required().isValidSync(email);
}

function ShareTitle(props: any) {
  return (
    <h1
      css={{
        margin: "0 0 4px 0",
        fontSize: 18,
        color: palette.darkGray,
        fontWeight: 600,
      }}
      {...props}
    />
  );
}

function ShareSubtitle(props: any) {
  return (
    <h3
      css={{
        fontWeight: 400,
        margin: 0,
        fontSize: 12,
        color: palette.sealBlue,
        "& strong": {
          fontWeight: 500,
        },
      }}
      {...props}
    />
  );
}

const InviteLinkButton = styled.button({
  color: palette.primary,
  background: "none",
  border: 0,
  outline: 0,
  padding: 0,
  fontSize: 14,
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
});

const AddEmailButton = styled.button({
  width: 42,
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  borderRadius: 4,
  outline: "none",
  border: `solid 1px ${palette.lightestGray}`,
  cursor: "pointer",
  color: palette.mediumGray,
});

export default InviteModal;
