import { gql } from "@apollo/client";
import CommentFieldsFragment from "~/src/components/comments/graphql/fragments/CommentFieldsFragment";

export default gql`
  query CommentsList($targetId: ObjectId!, $targetType: CommentTargetType!, $cursor: Cursor) {
    comments(targetId: $targetId, targetType: $targetType, cursor: $cursor) {
      items {
        ...CommentFields
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${CommentFieldsFragment}
`;
