import React, { useState } from "react";
import { Box, Text, Button, Flex } from "@govlaunch/web";
import { IProjectBySlugQuery } from "~/src/components/project/graphql/__generated__/ProjectBySlugQuery.generated";
import Collaborator from "~/components/invite/Collaborator";
import { IUser } from "~/types/types";
import InviteModal from "~/components/invite/InviteModal";
import { FormattedMessage, useIntl } from "react-intl";

interface IProjectCollaborators {
  project: NonNullable<IProjectBySlugQuery["projectBySlug"]>;
}

export default function ProjectCollaborators({ project }: IProjectCollaborators) {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const intl = useIntl();

  if (!project.viewerCanInviteCollaborators && project.collaborators.length === 0) {
    return null;
  }

  return (
    <Box borderRadius="md" border="solid 1px" borderColor="gray.200" pt={2} px={4}>
      <Text color="blue.600" fontSize="lg" fontWeight="bold" mb={1}>
        <FormattedMessage defaultMessage="Collaborators" id="9Jstnt" />
      </Text>

      {project.viewerCanInviteCollaborators && (
        <Flex alignItems="center" mb={2}>
          <Text fontSize="sm" color="gray.600" mr={1} lineHeight="18px">
            <FormattedMessage defaultMessage="Have you collaborated with someone on this Project?" id="pHy3pK" />{" "}
            <Button
              variant="link"
              fontSize="sm"
              color="blue.600"
              fontWeight="normal"
              lineHeight="18px"
              verticalAlign="baseline"
              onClick={() => setShowInviteModal(true)}
            >
              <FormattedMessage defaultMessage="Invite them!" id="6vp4p4" />
            </Button>
          </Text>
        </Flex>
      )}

      {project.collaborators.length > 0 && (
        <Flex wrap="wrap">
          {project.collaborators.map(collaborator => (
            <Box mb={2} mr={4} key={collaborator._id}>
              <Collaborator
                user={collaborator as IUser}
                viewerCanRemove={project.viewerCanRemoveCollaborators}
                targetId={project._id}
                targetType="PROJECT"
              />
            </Box>
          ))}
        </Flex>
      )}

      <InviteModal
        title={intl.formatMessage({
          defaultMessage: 'Invite Collaborators', id: 'WzMyYb',
        })}
        subtitle={
          <span>
            <FormattedMessage
              defaultMessage="They’ll be granted with <strong>Edit</strong> access to this <strong>Project.</strong>" id="rPBob9"
              values={{
                // eslint-disable-next-line react/display-name
                strong: (text: string) => <strong>{text}</strong>,
              }}
            />
          </span>
        }
        targetType="PROJECT"
        targetId={project._id}
        isOpen={showInviteModal}
        onRequestClose={() => setShowInviteModal(false)}
      />
    </Box>
  );
}
