import React, { ReactNode } from "react";
import { Box, Text } from "@govlaunch/web";
import { useSelfie } from "~/components/auth/Auth";
import useSignInModal from "~/src/components/auth/useSignInModal";
import { ISelfieQuery } from "~/lib/queries/__generated__/SelfieQuery.generated";
import { FormattedMessage } from "react-intl";

interface IGatedCommentsListProps {
  text?: ReactNode;
}

export default function GatedCommentsList({
  text = "🚫 You must be a verified user to join the discussion.",
}: IGatedCommentsListProps) {
  const user = useSelfie();
  const { open } = useSignInModal();

  return (
    <Box px={8} py={4} bg="gray.100">
      <Box bg="white" py={1} px={2}>
        {user ? (
          text
        ) : (
          <Text>
            <span css={{ marginRight: 2 }}>🚫</span>{" "}
            <FormattedMessage
              defaultMessage="To join the discussion, <link>sign up.</link> It's free!" id="qRAJSg"
              values={{
                // eslint-disable-next-line react/display-name
                link: (text: string) => (
                  <Text as="span" color="blue.600" role="button" onClick={open} cursor="pointer">
                    {text}
                  </Text>
                ),
              }}
            />
          </Text>
        )}
      </Box>
    </Box>
  );
}

interface IUserTypeMessages {
  pending?: ReactNode;
  citizen?: ReactNode;
  community?: ReactNode;
  vendor?: ReactNode;
  government?: ReactNode;
}

export function getGatedMessageFromUserType(
  viewer: ISelfieQuery["selfie"],
  { pending, citizen, community, vendor, government }: IUserTypeMessages,
  fallbackMessage = (
    <span>
      <span css={{ marginRight: 2 }}>🚫</span>{" "}
      <FormattedMessage defaultMessage="You must be a verified user to join the discussion." id="tRqSX+" />
    </span>
  ),
) {
  if (!viewer) {
    return fallbackMessage;
  }

  if (viewer.__typename === "PendingUser") {
    if (viewer.role === "CITIZEN") {
      return citizen || fallbackMessage;
    }

    if (viewer.verified) {
      return community || fallbackMessage;
    }

    return pending || fallbackMessage;
  }

  if (viewer.__typename === "VendorUser") {
    return vendor || fallbackMessage;
  }

  if (viewer.__typename === "GovernmentUser") {
    return government || fallbackMessage;
  }
}
