import { useState, RefObject } from "react";

export default function useClipboard(innerRef: RefObject<HTMLInputElement>): [boolean, (arg?: any) => any] {
  const [copySuccess, setCopySuccess] = useState(false);

  function setClipboard() {
    if (!innerRef.current) {
      return;
    }

    innerRef.current.select();

    document.execCommand("copy");

    innerRef.current.focus();

    setCopySuccess(true);

    setInterval(() => setCopySuccess(false), 2000);
  }

  return [copySuccess, setClipboard];
}
